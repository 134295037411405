import React, { useState, useEffect } from 'react';
import { TOTP } from 'totp-generator';

function App() {
  const [otp, setOtp] = useState('');
  const [expiresIn, setExpiresIn] = useState(0);
  const [copied, setCopied] = useState(false);

  const generateOtp = () => {
    const { otp, expires } = TOTP.generate('B2W4OBHVB4UHZTRC');
    setOtp(otp);
    setExpiresIn(expires - Date.now());
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(otp);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied status after 2 seconds
  };

  useEffect(() => {
    generateOtp();

    const interval = setInterval(() => {
      const { otp, expires } = TOTP.generate('B2W4OBHVB4UHZTRC');
      setOtp(otp);
      setExpiresIn(expires - Date.now());
    }, 10000); // Update OTP every 10 seconds (TOTP typically has a 30-second period)

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (expiresIn > 0) {
      const timeout = setTimeout(() => setExpiresIn(expiresIn - 1000), 1000);
      return () => clearTimeout(timeout);
    } else {
      generateOtp();
    }
  }, [expiresIn]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '50px' }}>
      <h1>TOTP For KAST Demo Account</h1>
      <div style={{ fontSize: '2rem', marginBottom: '10px' }}>{otp}</div>
      <button
        onClick={copyToClipboard}
        style={{
          marginBottom: '20px',
          padding: '10px 20px',
          fontSize: '1rem',
          cursor: 'pointer',
        }}
      >
        {copied ? 'Copied!' : 'Copy'}
      </button>
      <div style={{ fontSize: '1.5rem' }}>Expires in: {Math.max(expiresIn / 1000, 0).toFixed(0)} seconds</div>
    </div>
  );
}

export default App;
